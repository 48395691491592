
@import "assets/style";

body {
  overflow-y: scroll;
  min-height: 100vh;
}

.uk-navbar-nav li {
  padding: 0 !important;
  margin: 0 !important;

  a {
    min-height: 60px;
  }
}
